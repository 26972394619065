<template>
  <div class="t_intraduction_resource1">
    <el-dialog :visible.sync="dialog" title="课程资源" width="50%">
      <el-form :model="form" inline>
        <el-form-item label="名称">
          <el-input v-model="form.name" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="data" :height="250" ref="tableRef" stripe>
        <!-- <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column> -->
        <el-table-column
          v-for="(item, index) in columns"
          :prop="item.prop"
          :key="index"
          :label="item.label"
          :min-width="item.width"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column align="center" width="160" prop="resourceType" label="资源类型">
          <template slot-scope="scope">
            <!--            <span v-if="scope.row.fileSize">{{ (scope.row.fileSize / 1024).toFixed(2) }} kb</span>-->
            <span v-if="scope.row.resourceType == 4">图片</span>
            <span v-if="scope.row.resourceType == 3">视频</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="160" prop="resourceSize" label="文件大小">
          <template slot-scope="scope">
<!--            <span v-if="scope.row.fileSize">{{ (scope.row.fileSize / 1024).toFixed(2) }} kb</span>-->
            <span v-if="scope.row.resourceSize">{{ (scope.row.resourceSize / 1024).toFixed(2) }} kb</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.length"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "t_intraduction_resource1",
  props: ["data"],
  data() {
    return {
      dialog: false,
      form: { name: "" },
      columns: [
        { prop: "labelName", label: "标题" },
        // { prop: "fileSize", label: "文件大小" },
        // { prop: "resourceType", label: "资源类型" },
        { prop: "createDate", label: "创建时间" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
    };
  },
  methods: {
    query() {},
    confirm() {
      this.dialog = false;
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
  },
};
</script>

<style scoped lang="less">
.t_intraduction_resource1 {
}
</style>
<template>
  <div class="t_intraduction_upload_video">
    <el-dialog :visible.sync="dialog" title="上传资源" width="30%">
      <el-upload
          class="avatar-uploader"
          :action="`${url}hnjxjy-core/eduCourse/updateEduCourse`"
          :data="{
          id: this.course.id,
        }"
          :name="fileType === 'video' ? 'file2' : 'file'"
          :headers="{
          'x-token': auth['x-token'],
          'x-perm': auth['x-perm'],
        }"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-progress="handleProgress"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <el-progress :percentage="percentage"></el-progress>
    </el-dialog>
  </div>
</template>

<script>
import {BASE_URL} from "@/libs/domain";

export default {
  name: "t_intraduction_upload_video",
  props: ["fileType"],
  data() {
    return {
      dialog: false,
      percentage: 0,
      boolItem: false
    };
  },
  computed: {
    url() {
      return BASE_URL;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    user() {
      return this.$store.getters.getUser;
    },
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  methods: {
    recursivePercent() {
      if (this.boolItem) {
        return;
      }
      const p = this.percentage;
      let addPercent = Math.round(Math.random() * 4) + 1;
      if (p <= 94) {
        this.percentage = this.percentage + addPercent;
      }
      setTimeout(() => {
        this.recursivePercent();
      }, 1000);
    },
    handleChange(file) {
      // this.$emit("imageHandleChange", file);
    },
    handleAvatarSuccess(res) {
      if (res.code === 2000) {
        this.percentage = 100;
        this.boolItem = true;
        this.$message.success("修改成功");
        this.$emit("query");
        this.dialog = false;
      }
      this.percentage = 0;
    },
    handleProgress() {
      this.boolItem = false;
      this.recursivePercent();
    },
  },
};
</script>

<style lang="less">
.t_intraduction_upload_video {
  .avatar-uploader {
    text-align: center;

    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      margin: 20px 0;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>

<style lang="less" scoped>
.t_intraduction {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    position: relative;
    .coverUrl_div {
      max-height: 500px;
      overflow: auto;
      .coverUrl {
        width: 100%;
        background: #f5f7fa;
        border-radius: 4px;
      }
      .image-none {
        border-radius: 4px;
        width: 60%;
        margin: 5% auto 0;
        display: block;
      }
    }
  }
}
</style>
<template>
  <div class="t_intraduction">
    <div class="stu-module-header">
      <div class="stu-module-title">课程简介</div>
      <div>
        <el-button v-if="active === '1'" type="primary" @click="update">
          更新简介
        </el-button>
        <el-button v-if="active === '2'" type="primary" @click="resource1">
          课程资源
        </el-button>
        <el-button v-if="active === '2'" type="primary" @click="uploadVideo">
          上传视频
        </el-button>
        <p v-if="active === '2'" style="color: red;font-size: 14px; margin-left: 85px;margin-top: 10px; font-weight: bold;">仅支持mp4格式</p>
        <el-button v-if="active === '3'" type="primary" @click="resource2">
          课程资源
        </el-button>
        <el-button v-if="active === '3'" type="primary" @click="uploadImg">
          上传图片
        </el-button>
        <p v-if="active === '3'" style="color: red;font-size: 14px; margin-left: 65px;margin-top: 10px; font-weight: bold;">仅支持jpg,png格式</p>
      </div>
    </div>
    <div class="content" v-loading="loading">
      <el-tabs type="border-card" v-model="active">
        <el-tab-pane label="课程简介" name="1">
          <QuillEditor
            :paramsText="paramsText"
            @quillEditorHtml="quillEditorHtml($event)"
            @uploadFile1="uploadFile1"
            :resultMethods="'uploadFile1'"
          />
        </el-tab-pane>
        <el-tab-pane label="课程视频" name="2">
          <CourseVideo
            ref="courseVideo"
            v-if="videoUrl != null && videoUrl != ''"
            :videoUrl="videoUrl"
          />
        </el-tab-pane>
        <el-tab-pane label="课程封面" name="3">
          <div class="coverUrl_div">
            <el-image :src="coverUrl" class="coverUrl">
              <div slot="error" class="image-none">
                <img class="image-none" src="../../../../assets/logo_top.png" />
                <!-- <i class="el-icon-picture-outline"></i> -->
              </div>
            </el-image>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <Resource ref="resource1_ref" :data="resourcesData" />
    <UploadVideo
      @query="query"
      :fileType="active === '2' ? 'video' : 'image'"
      ref="uploadVideo_ref"
      @imageHandleChange="imageHandleChange"
    />
  </div>
</template>

<script>
import QuillEditor from "@/components/teacher/QuillEditor.vue";
import Resource from "./module/Resource.vue";
import UploadVideo from "./module/UploadVideo.vue";
import CourseVideo from "./module/CourseVideo.vue";
import { EduCourseList, UpdateEduCourse } from "@/libs/api/teacher/resources";
export default {
  name: "t_intraduction",
  components: { QuillEditor, Resource, UploadVideo, CourseVideo },
  data() {
    return {
      loading: false,
      videoUrl: "",
      paramsText: "",
      active: "1",
      coverUrl:
        "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
      resourcesData: [],
      editorHtml: "",
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  activated() {
    this.query();
  },
  methods: {
    uploadFile1(str) {
      if (null == this.paramsText) {
        this.paramsText = str;
      } else {
        this.paramsText = this.paramsText + str;
      }
    },
    imageHandleChange(row) {
      let formData = new FormData();
      if (row.raw.type == "video/mp4") {
        formData.append("file2", row.raw);
      } else if (row.raw.type.toString().split("/")[0] == "image") {
        formData.append("file", row.raw);
      }
      formData.append("id", this.course.id);
      // this.$axios_supermall
      //   .post("/hnjxjy-core/eduCourse/updateEduCourse", formData)
      UpdateEduCourse(formData).then((res) => {
        this.$message.success("修改成功");
        this.query();
        this.$refs["uploadVideo_ref"].dialog = false;
      });
    },
    query() {
      let formData = new FormData();
      formData.append("id", this.course.id);
      // this.openLoadingView();
      this.loading = true;
      // this.$axios_supermallData
      //   .post("/hnjxjy-core/eduCourse/list", formData)
      EduCourseList(formData)
        .then((res) => {
          this.paramsText = res.data.courseDesc;
          this.videoUrl = res.data.videoUrl;
          this.coverUrl = res.data.coverUrl;
          this.loading = false;
          // this.loadingView.close();
        })
        .catch(() => {
          this.loading = false;
          // this.loadingView.close();
        });
    },
    quillEditorHtml(item) {
      this.editorHtml = item;
    },
    update() {
      let formData = new FormData();
      formData.append("courseDesc", this.editorHtml);
      formData.append("id", this.course.id);
      this.$axios_supermall
        .post("/hnjxjy-core/eduCourse/updateEduCourse", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success("修改成功");
            this.query();
          }
        });
    },
    resource1() {
      let formData = new FormData();
      formData.append("page", "1");
      formData.append("pageSize", "10");
      formData.append("courseId", this.course.id);
      formData.append("resourceType", "3");
      this.$axios_supermall
        .post("/hnjxjy-core/eduCourseResource/list", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.resourcesData = resp.data.data.list;
          }
        });
      this.$refs["resource1_ref"].dialog = true;
    },
    uploadVideo() {
      this.$refs["uploadVideo_ref"].dialog = true;
    },
    resource2() {
      let formData = new FormData();
      formData.append("page", "1");
      formData.append("pageSize", "10");
      formData.append("courseId", this.course.id);
      formData.append("resourceType", "4");
      this.$axios_supermall
        .post("/hnjxjy-core/eduCourseResource/list", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.resourcesData = resp.data.data.list;
          }
        });

      this.$refs["resource1_ref"].dialog = true;
    },
    uploadImg() {
      this.$refs["uploadVideo_ref"].dialog = true;
    },
  },
};
</script>
